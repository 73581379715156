<template>
  <SmartTable
    :class="[role, 'permissionTable']"
    :items="permissions"
    :columns="columns.map(i => ({ ...i, sorter: false }))"
    style="overflow-y: unset !important;"
    infinity
  >
<!--    <template #none="{ item }">-->
<!--      <td-->
<!--        class="text-center"-->
<!--        @mouseenter="hoverCol(2, item._id + 1)"-->
<!--        @mouseleave="leaveCol(2, item._id + 1)"-->
<!--      >-->
<!--        <CTooltip :content="tooltips[item.type].none" placement="top">-->
<!--          <template #toggler="{ on }">-->
<!--            <CFormCheck-->
<!--              v-model="item['none']"-->
<!--              v-on="checkTooltip(item.type, 'none', on)"-->
<!--              @change="handleChangeNone($event, item._id)"-->
<!--            />-->
<!--          </template>-->
<!--        </CTooltip>-->
<!--      </td>-->
<!--    </template>-->
    <template #view="{ item }">
      <td
        class="text-center"
        @mouseenter="hoverCol(2, item._id + 1)"
        @mouseleave="leaveCol(2, item._id + 1)"
      >
        <CTooltip
          v-if="checkShow(item.view)"
          :content="tooltips[item.type].view"
          placement="top"
        >
          <template #toggler="{ on }">
            <CCol v-on="checkTooltip(item.type, 'view', on)">
              <CFormCheck
                :model-value="item['view']['checked']"
                :disabled="item['view']['disabled']"
                @change="handleChangePermission($event, item, 'view')"
              />
            </CCol>
          </template>
        </CTooltip>
      </td>
    </template>
    <template #communication="{ item }">
      <td
        class="text-center"
        @mouseenter="hoverCol(3, item._id + 1)"
        @mouseleave="leaveCol(3, item._id + 1)"
      >
        <CTooltip
          v-if="checkShow(item.communication)"
          :content="tooltips[item.type].communication"
        >
          <template #toggler="{ on }">
            <CCol v-on="checkTooltip(item.type, 'communication', on)">
              <CFormCheck
                :model-value="item['communication']['checked']"
                :disabled="item['communication']['disabled']"
                @change="handleChangePermission($event, item, 'communication')"
              />
            </CCol>
          </template>
        </CTooltip>
      </td>
    </template>
    <template #create="{ item }">
      <td
        class="text-center"
        @mouseenter="hoverCol(4, item._id + 1)"
        @mouseleave="leaveCol(4, item._id + 1)"
      >
        <CTooltip
          v-if="checkShow(item.create)"
          :content="tooltips[item.type].create"
        >
          <template #toggler="{ on }">
            <CCol v-on="checkTooltip(item.type, 'create', on)">
              <CFormCheck
                :model-value="item['create']['checked']"
                :disabled="item['create']['disabled']"
                @change="handleChangePermission($event, item, 'create')"
              />
            </CCol>
          </template>
        </CTooltip>
      </td>
    </template>
    <template #edit="{ item }">
      <td
        class="text-center"
        @mouseenter="hoverCol(5, item._id + 1)"
        @mouseleave="leaveCol(5, item._id + 1)"
      >
        <CTooltip
          v-if="checkShow(item.edit)"
          :content="tooltips[item.type].edit"
        >
          <template #toggler="{ on }">
            <CCol v-on="checkTooltip(item.type, 'edit', on)">
              <CFormCheck
                :model-value="item['edit']['checked']"
                :disabled="item['edit']['disabled']"
                @change="handleChangePermission($event, item, 'edit')"
              />
            </CCol>
          </template>
        </CTooltip>
      </td>
    </template>
    <template #delete="{ item }">
      <td
        class="text-center"
        @mouseenter="hoverCol(6, item._id + 1)"
        @mouseleave="leaveCol(6, item._id + 1)"
      >
        <CTooltip
          v-if="checkShow(item.delete)"
          :content="tooltips[item.type].delete"
        >
          <template #toggler="{ on }">
            <CCol v-on="checkTooltip(item.type, 'delete', on)">
              <CFormCheck
                :model-value="item['delete']['checked']"
                :disabled="item['delete']['disabled']"
                @change="handleChangePermission($event, item, 'delete')"
              />
            </CCol>
          </template>
        </CTooltip>
      </td>
    </template>
    <template #assign="{ item }">
      <td
        class="text-center"
        @mouseenter="hoverCol(7, item._id + 1)"
        @mouseleave="leaveCol(7, item._id + 1)"
      >
        <CTooltip
          v-if="checkShow(item.assign)"
          :content="tooltips[item.type].assign"
        >
          <template #toggler="{ on }">
            <CCol v-on="checkTooltip(item.type, 'assign', on)">
              <CFormCheck
                :model-value="item['assign']['checked']"
                :disabled="item['assign']['disabled']"
                @change="handleChangePermission($event, item, 'assign')"
              />
            </CCol>
          </template>
        </CTooltip>
      </td>
    </template>
    <template #approve="{ item }">
      <td
        class="text-center"
        @mouseenter="hoverCol(8, item._id + 1)"
        @mouseleave="leaveCol(8, item._id + 1)"
      >
        <CTooltip
          v-if="checkShow(item.approve)"
          :content="tooltips[item.type].approve"
        >
          <template #toggler="{ on }">
            <CCol v-on="checkTooltip(item.type, 'approve', on)">
              <CFormCheck
                :model-value="item['approve']['checked']"
                :disabled="item['approve']['disabled']"
                @change="handleChangePermission($event, item, 'approve')"
              />
            </CCol>
          </template>
        </CTooltip>
      </td>
    </template>
    <template #sign="{ item }">
      <td
        class="text-center"
        @mouseenter="hoverCol(9, item._id + 1)"
        @mouseleave="leaveCol(9, item._id + 1)"
      >
        <CTooltip
          v-if="checkShow(item.sign)"
          :content="tooltips[item.type].sign"
        >
          <template #toggler="{ on }">
            <CCol v-on="checkTooltip(item.type, 'sign', on)">
              <CFormCheck
                :model-value="item['sign']['checked']"
                :disabled="item['sign']['disabled']"
                @change="handleChangePermission($event, item, 'sign')"
              />
            </CCol>
          </template>
        </CTooltip>
      </td>
    </template>
  </SmartTable>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
