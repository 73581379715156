<template>
  <div class="permissionGroup">
    <CCard>
      <teleport v-if="mountedComponent" to="#header-nav">
        <CBreadcrumbItem active> Permission Groups </CBreadcrumbItem>
      </teleport>

      <CCardBody v-loading="loading">
        <CContainer style="max-width: 100%">
          <ConfirmDialog ref="confirmDialog" />
          <CCol style="min-height: 70vh">
            <CRow>
              <CCol xs="12">
                <h4>Permission Groups</h4>
                <p>
                  Below you can see the permissions of user roles for a company with a plan
                  {{ $store.getters.isSubPlan ?  $t('plan.subContractor') : $t('plan.generalContractor') }}.
                  Hover the needed checkbox and read the tooltip to learn more information.
                </p>
                <p v-if="$store.getters.isGeneralPlan">
                  <strong>Role</strong>: Administrator / Owner of company -
                  <strong> Full Access </strong>
                </p>
              </CCol>
              <CCol
                v-for="(item, key) in permissions['general']"
                :key="key"
                xs="12"
              >
                <p>
                  <strong> Role: </strong>
                  {{ item['role_show_name'] }}
                </p>

                <PermissionTable
                  class="mb-5"
                  :permissions="item.permissions"
                  :role="item['role_name']"
                />
              </CCol>
            </CRow>
            <CRow v-if="permissions['sub'].length">
<!--              <CCol xs="12">-->
<!--                <strong>-->
<!--                  Users who are RECEIVING tasks, for example Sub-contractors-->
<!--                </strong>-->
<!--                <p>-->
<!--                  Receiving users will only have access to projects, tasks and-->
<!--                  documents that have been assigned to their company and will-->
<!--                  only have permission to see their own company details and-->
<!--                  users-->
<!--                </p>-->
<!--              </CCol>-->
              <CCol
                v-for="(item, key) in permissions['sub']"
                :key="key"
                xs="12"
              >
                <p>
                  <strong> Role: </strong>
                  {{ item['role_show_name'] }}
                </p>

                <PermissionTable
                  class="mb-5"
                  :permissions="item.permissions"
                  :role="item['role_name']"
                />
              </CCol>
            </CRow>
          </CCol>

<!--          <CRow class="justify-content-center justify-content-md-start w-100">-->
<!--            <CCol xs="12" md="12" sm="12" lg="4">-->
<!--              <CButton color="primary" class="w-100" @click="handleSave">-->
<!--                Save permission role changes-->
<!--              </CButton>-->
<!--            </CCol>-->
<!--            <CCol xs="12" md="12" sm="12" lg="2">-->
<!--              <CButton variant="ghost" class="w-100" @click="handleCancel">-->
<!--                Cancel-->
<!--              </CButton>-->
<!--            </CCol>-->
<!--          </CRow>-->
        </CContainer>
      </CCardBody>
    </CCard>
  </div>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
