import { mapState } from 'vuex'

export default {
  inject: ['toast'],
  props: {
    permissions: { type: Array, default: {} },
    role: { type: String, default: '' },
  },
  components: {},
  data() {
    return {
      items: [],
    }
  },
  watch: {
    permissions: {
      handler: function (newVal) {
        newVal.forEach((row, index) => {
          // Check View
          this.controlKeys.forEach((el) => {
            if (row[el] && row[el]['checked'])
              this.permissions[index]['view']['checked'] = true
          })

          // Check None
          for (let key in row) {
            let checked = !!row[key]['checked'] === true
            if (!this.exceptKeys.includes(key) && checked)
              this.permissions[index].none = false
          }
        })
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      tooltips: (state) => state.permissionGroup.tooltips,
      columns: (state) => state.permissionGroup.columns,
      formKeys: (state) => state.permissionGroup.formKeys,
      exceptKeys: (state) => state.permissionGroup.exceptKeys,
      configTypes: (state) => state.permissionGroup.configTypes,
      controlKeys: (state) => state.permissionGroup.controlKeys,
    }),
  },
  methods: {
    checkTooltip(type, key, directive) {
      return this.tooltips[type][key].length ? directive : null
    },
    handleChangeNone(event, id) {
      if ((this.permissions[id]['none'] = event.target.checked)) {
        for (let key in this.permissions[id]) {
          if (!this.exceptKeys.includes(key)) {
            this.permissions[id][key]['checked'] = false
          }
        }
      }
    },
    handleChangePermission(event, item, key) {
      this.permissions[item._id][key]['checked'] = !!event.target.checked
      this.validChecked(item, key)
      this.validDisabled(item, key)
    },
    validChecked(permission, key) {
      this.configTypes[permission.type][key]['dependent'].forEach((item) => {
        if (permission[key]['checked'])
          permission[item]['checked'] = permission[key]['checked']
      })
    },
    validDisabled(permission, key) {
      this.configTypes[permission.type][key]['disabled'].forEach((item) => {
        permission[item]['disabled'] = !permission[key]['checked']
        if (!permission[key]['checked']) permission[item]['checked'] = false
      })
    },
    checkShow(item = undefined) {
      return item && typeof item.checked === 'boolean'
    },
    hoverCol(colKey, rowKey) {
      let el = document.querySelector(
        `.${this.role} table thead tr:nth-child(1) th:nth-child(${colKey})`,
      )

      let elTd = document.querySelector(
        `.${this.role} table tbody tr:nth-child(${rowKey}) td:nth-child(1)`,
      )
      elTd.classList.add('tdBg')
      el.classList.add('thBg')
    },
    leaveCol(colKey, rowKey) {
      let el = document.querySelector(
        `.${this.role} table thead tr:nth-child(1) th:nth-child(${colKey})`,
      )

      let elTd = document.querySelector(
        `.${this.role} table tbody tr:nth-child(${rowKey}) td:nth-child(1)`,
      )
      elTd.classList.remove('tdBg')
      el.classList.remove('thBg')
    },
  },
}
